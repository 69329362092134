<template>
  <div class="message-page">
    <v-container>
      <v-card class="pa-4 mt-5" rounded elevation="0">
        <v-card-text class="pa-4">
          <div class="d-block text-center mt-16 mb-5">
            <img alt="logo" src="@/assets/logo_gifty.svg" width="120" />
          </div>

          <div>

            <div>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:center; line-height:normal; font-size:16pt">
				<strong><u><span style="font-family:'Times New Roman'; ">CONDITIONS </span></u></strong><strong><u><span style="font-family:'Times New Roman'; font-size:14pt; ">GENERALES D’UTILISATION («</span></u></strong><strong><u><span style="font-family:'Times New Roman'; font-size:14pt; ">&#xa0;</span></u></strong><strong><u><span style="font-family:'Times New Roman'; font-size:14pt; ">CG</span></u></strong><strong><u><span style="font-family:'Times New Roman'; ">U</span></u></strong><strong><u><span style="font-family:'Times New Roman'; ">&#xa0;</span></u></strong><strong><u><span style="font-family:'Times New Roman'; ">»)</span></u></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify; page-break-after:avoid; line-height:normal; font-size:10pt">
				<em>Dernière mise à jour le</em><em>&#xa0;</em><em>: 17/07/2023</em>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Merci de lire attentivement les présentes Conditions Générales avant d'utiliser les services de la Plateforme de la société GIFTY TECHNOLOGY SARL, (ci-après dénommée</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">«</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">la Société</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">»).</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<ol type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; text-align:justify; line-height:normal; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>INTRODUCTION </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Les présentes Conditions Générales d’Utilisation s’appliquent à la Plateforme «</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">GIFTY</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">» (Ci-après dénommée « la Plateforme »). Les présentes CGU régissent votre utilisation de notre Plateforme qui est une marketplace de cadeau digital sous forme de bons d’achat électronique, permettant d’acquérir ou d’offrir un cadeau à titre professionnelle ou personnelle.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">En utilisant notre Plateforme, vous acceptez d'être lié par ces CGU.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Les présentes CGU sont conclues entre tout Utilisateur de GIFTY (ci-après dénommé « l’Utilisateur » ou «</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">Vous</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">») et la Société GIFTY TECHNOLOGY SARL immatriculée au registre du commerce d’Alger sous le numéro 22B1018496 et dont le siège social est situé au Coopérative immobilière El Bouroudj N05, étage 02, bureau 33, Dely Ibrahim Alger, Algérie. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Il est porté à la connaissance de l’Utilisateur que les bons d’achat électroniques ne représente pas de la monnaie électronique.</span>
			</p>
			<ol start="2" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; margin-bottom:10pt; text-align:justify; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>ACCEPTATION DES CGU</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société rappelle aux Utilisateurs de lire attentivement et de bien comprendre le contenu des présentes Conditions Générales d’Utilisation. L’utilisation de la Plateforme représente un consentement tacite aux présentes CGU. N’utilisez pas la Plateforme si Vous n’êtes pas d’accord avec ces Conditions Générales. L’Utilisateur est responsable de vérifier régulièrement toute modification apportée aux présentes Conditions Générales qui sera publiée. L’utilisation de la Plateforme après la publication des Conditions Générales d’Utilisation représente l’acceptation pleine et sans réserve de celles-ci.</span>
			</p>
			<ol start="3" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; margin-bottom:10pt; text-align:justify; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>DROITS DE PROPRIETE INTELLECTUELLE</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Tous les droits de propriété intellectuelle liés à notre Plateforme, y compris, mais sans s'y limiter, les droits d'auteur, les marques commerciales, les brevets, les secrets commerciaux et autres droits de propriété, sont la propriété exclusive de la Société ou de ses concédants de licence. Vous reconnaissez que l'utilisation de notre Plateforme ne vous confère aucun droit de propriété intellectuelle sur celle-ci.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous conservez tous les droits de propriété intellectuelle sur le contenu que vous créez et mettez à disposition via notre Plateforme. En téléchargeant, publiant ou partageant du contenu, vous nous accordez une licence limitée pour utiliser, reproduire, distribuer, afficher publiquement, modifier et adapter ce contenu dans le cadre de la prestation de nos services, conformément aux présentes CGU.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous pouvez télécharger des informations sur les produits et services de la Société sur la Plateforme, à condition que ces informations ne soient pas utilisées à des fins commerciales et ne soient pas modifiées. Des garanties et des représentations supplémentaires sont requises pour les informations susmentionnées et les documents qui les contiennent, ainsi que la déclaration des droits et intérêts légitimes de la Société.</span>
			</p>
			<ol start="4" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; margin-bottom:10pt; text-align:justify; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>SIGNALEMENT DES ATTEINTES AUX DROITS DE PROPRIETE INTELLECTUELLE </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Nous respectons les droits de propriété intellectuelle d'autrui et nous nous engageons à répondre aux notifications d'atteinte présumée aux droits d'auteur ou à tout autre droit de propriété intellectuelle. Si Vous estimez que votre contenu a été utilisé de manière à violer vos droits de propriété intellectuelle, veuillez nous contacter à contact@gifty-dz.com avec les informations suivantes : (a) une description détaillée du contenu en question, (b) vos coordonnées et (c) une déclaration attestant que vous êtes le titulaire des droits ou que vous êtes autorisé à agir au nom du titulaire des droits.</span>
			</p>
			<ol start="5" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; text-align:justify; line-height:normal; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>LICENCE LIMITEE</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Sous réserve du respect des présentes CGU, nous Vous accordons une licence limitée, non exclusive, révocable et non transférable pour utiliser notre Plateforme à des fins personnelles et non commerciales. Cette licence ne vous autorise pas à utiliser notre Plateforme d'une manière qui contreviendrait à nos droits de propriété intellectuelle ou à ceux de tiers.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="6" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; text-align:justify; line-height:normal; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>UTILISATION DU CONTENU </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’utilisation de la Plateforme est réservée aux personnes ayant atteint la majorité et habilités à donner leurs consentements quant aux présentes CGU. Si vous n'avez pas atteint la majorité, vous devez être accompagné par votre représentant légal pour enregistrer un compte, ce dernier doit accepter et avoir consenti à ces conditions d'utilisation en votre nom.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous pouvez utiliser le contenu mis à disposition dans notre Plateforme uniquement dans le cadre de l'utilisation autorisée de celle-ci. Toute reproduction, distribution, modification ou exploitation non autorisée du contenu est strictement interdite.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Il est strictement interdit d'utiliser notre Plateforme de manière à violer les droits de propriété intellectuelle de tiers. Si nous constatons une telle utilisation, nous nous réservons le droit de prendre des mesures, y compris la résiliation de votre compte et la suppression de tout contenu en violation des droits de propriété intellectuelle.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous ne devez pas cadrer ou utiliser des techniques de cadrage (framing) pour insérer toute marque, logo ou autre information commerciale (y compris des images, textes, mises en pages ou formes). </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous ne devez pas utiliser de méta tags ou tout autre « texte caché » utilisant le nom ou les marques déposées de la Société ou autres sociétés affiliées sans notre autorisation expresse et écrite.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous ne devez pas faire un mauvais usage d'un service de la Société. Vous devez utiliser les services de la Société comme autorisé par la loi. A défaut, la Société décline toutes responsabilités et fera le nécessaire pour Vous dénoncer aux autorités compétentes. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Les licences accordées par la Société prendront fin si Vous ne respectez pas ces Conditions Générales.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="7" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; margin-bottom:10pt; text-align:justify; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>CONDITION D’UTILISATION</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’Utilisateur ne doit pas utiliser la Plateforme à des fins illégales ou interdites et ne doit pas utiliser la Plateforme et son contenu pour violer les droits et les intérêts légitimes d’autres organisations ou individus.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous ne devez pas utiliser d’appareil, de programme, d’algorithme ou de méthode automatisé, y compris « lien profond », « Scrape de page », « robot Web », « outils de capture Web », ou tout autre processus manuel similaire ou équivalent pour accéder, obtenir, copier ou surveiller tout composant de la Plateforme ou tout contenu, structure ou forme de présentation, obtenir ou tenter d’obtenir des informations, des manuscrits ou des informations par tout moyen non fourni par la Plateforme.</span><span style="font-family:'Times New Roman'">&#xa0; </span><span style="font-family:'Times New Roman'">La Société se réserve le droit d’interdire tout type d’activité de ce type. L’Utilisateur ne doit pas tenter d’obtenir un accès non autorisé à tout contenu ou d’utiliser tout service de la Plateforme par tout moyen illégal. L’Utilisateur ne doit lancer aucun type d’attaque réseau sur la Plateforme. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’Utilisateur s’engage par ailleurs à ne pas</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">: </span>
			</p>
			<ol type="1" style="margin:0pt; padding-left:0pt">
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Utiliser la Plateforme pour usurper l’identité de toute personne ou entité, ou présenter de manière inexacte votre affiliation avec une personne ou une entité&#xa0;;
				</li>
			</ol>
			<ol start="2" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:16.55pt; text-align:justify; line-height:13.5pt; padding-left:4.75pt; font-family:'Times New Roman'">
					Directement ou indirectement, utiliser
				</li>
			</ol>
			<p style="margin-top:0pt; margin-left:3.3pt; margin-bottom:0pt; text-align:justify; line-height:13.5pt">
				<span style="font-family:'Times New Roman'">La Plateforme à des fins commerciales, sauf autorisation contraire la société</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">;</span>
			</p>
			<ol start="3" type="1" style="margin:0pt; padding-left:0pt">
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Prendre toute mesure susceptible de saper ou de manipuler le système de commentaires ou de notation&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Etre perturbateur, être offensant ou être une nuisance de quelque manière que ce soit pour les autres Utilisateurs de la plateforme ou les employés de la Société&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Récolter ou collecter des informations sur ou concernant d'autres titulaires de compte, y compris, sans s'y limiter, des informations personnelles ou professionnelles&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Télécharger, envoyer par e-mail, publier, transmettre ou mettre à disposition de toute autre manière toute publicité non sollicitée ou non autorisée, matériel promotionnel, «&#xa0;courrier indésirable&#xa0;», «&#xa0;spam&#xa0;», «&#xa0;chaînes de lettres&#xa0;», «&#xa0;systèmes pyramidaux&#xa0;» ou toute autre forme de sollicitation non autorisée&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Télécharger, envoyer par courrier électronique, publier, transmettre ou rendre disponible de toute autre manière tout matériel contenant des virus logiciels, des vers, des chevaux de troie ou tout autre code informatique, routines, fichiers ou programmes conçus pour interférer directement ou indirectement avec, manipuler, interrompre, détruire ou limiter le la fonctionnalité ou l'intégrité de tout logiciel ou matériel informatique ou équipement de données ou de télécommunications&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Interférer avec, manipuler ou perturber les services ou les serveurs ou réseaux connectés aux services ou toute autre utilisation et jouissance des services, ou désobéir aux exigences, procédures, politiques ou réglementations des réseaux connectés à la plateforme&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Prendre toute mesure ou s'engager dans une conduite qui pourrait directement ou indirectement endommager, désactiver, surcharger ou détériorer les services ou les serveurs ou réseaux connectés aux services&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					Utiliser les services pour violer, intentionnellement ou non, toute loi, règle, réglementation, code, directive, directive ou politique locale, étatique, nationale ou internationale applicable, y compris, sans s'y limiter, les lois et exigences (ayant ou non force de loi) relatives à lutte contre la fraude, le blanchiment d'argent, la lutte contre le terrorisme, les produits illégaux et la lutte contre la corruption&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					Utiliser les services en violation ou pour contourner toute sanction ou embargo administré ou toute loi appliquée&#xa0;;
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					Reproduire, tenter de reproduire ou contrefaire les bons d’achats de la Société&#xa0;; 
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					Recruter et/ou débaucher directement ou indirectement des clients obtenus grâce à l'utilisation de la plateforme.
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:13.5pt">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous devez vous conformer à toutes les lois et restrictions nationales d’Algérie et internationales applicables en matière de contrôle des exportations, y compris toute restriction sur la destination, l’Utilisateur final et l’utilisation finale. Nous nous réservons le droit de suspendre, de restreindre ou de résilier votre accès à la Plateforme ou à toute partie de celle-ci afin de nous conformer à ces lois et restrictions en matière de contrôle des exportations.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société se réserve le droit à tout moment de divulguer les informations nécessaires pour se conformer à toute loi, réglementation, procédure judiciaire ou exigence gouvernementale applicable. La Société peut également divulguer vos informations, y compris l’échange d’informations avec d’autres sociétés et organisations à des fins de protection contre la fraude, lorsque la Société estime qu’une telle divulgation est requise ou autorisée par la loi applicable en Algérie.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous confirmez et acceptez que la Société se réserve le droit de maintenir toute transmission ou communication via la Plateforme et tout service fourni sur la Plateforme, ou tout service fourni via la Plateforme, et de divulguer les données ci-dessus si: Cela est requis par la loi, ou la Société a de bonnes raisons de croire que seulement par cela, pourrait raisonnablement : (1) se conformer à une procédure judiciaire ; (2) exécuter les présentes Conditions Générales; (3) répondre aux plaintes selon lesquelles ces données ont violé les droits d’autrui; ou (4) protéger les droits, la propriété ou la sécurité personnelle de la Société et de ses employés, Utilisateurs ou visiteurs de la Plateforme et du public.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société peut, à sa seule discrétion et sans préavis, résilier votre accès à la Plateforme et / ou Vous empêcher d’accéder à la Plateforme à l’avenir si la Société estime que Vous avez violé les présentes Conditions Générales d’Utilisation ou tout autre condition ou directive pouvant être pertinent pour votre utilisation de la Plateforme.</span><span style="font-family:'Times New Roman'">&#xa0; </span><span style="font-family:'Times New Roman'">Toute violation des présentes Conditions Générales par Vous constituera une activité commerciale illégale et inappropriée et causera des dommages à la Société qui ne peuvent être compensés par une compensation monétaire seule, auquel cas la Société peut demander une injonction ou un recours équivalent, s’il le juge nécessaire ou approprié. Ces recours sont complémentaires à tout autre recours disponible pour la Société en vertu de la loi applicable ou en équité, et en plus de tout autre recours disponible pour la Société, la Société aura le droit de récupérer l’argent auprès de Vous et Vous acceptez de payer tous les honoraires d’avocat raisonnables et les honoraires d’avocat. Vous acceptez que la Société ne soit pas responsable envers Vous ou un tiers de la résiliation de votre accès à la Plateforme à la suite de votre violation de la présente condition.</span>
			</p>
			<ol start="8" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; text-align:justify; line-height:normal; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>COMPTE UTILISATEUR</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Pour utiliser certaines fonctionnalités de notre la Plateforme, Vous êtes responsable du maintien de la confidentialité de votre compte et mot de passe, des restrictions d'accès à votre ordinateur et autres équipements, et dans la limite de ce qui est autorisé par la loi applicable, Vous acceptez d'être responsable de toutes les activités qui ont été menées depuis votre compte ou avec votre mot de passe. Vous devez prendre toutes les mesures nécessaires pour Vous assurer que votre mot de passe reste confidentiel et sécurisé, et devez nous informer immédiatement si Vous avez des raisons de croire que votre mot de passe est connu de quelqu'un d'autre, ou si le mot de passe est utilisé ou susceptible d'être utilisé de manière non autorisée. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous êtes responsable de la validité et du caractère complet des informations que Vous nous avez fournies, et devez nous informer de tout changement concernant ces informations. Vous pouvez accéder à vos informations et les modifier, ainsi que vos paramètres de compte, dans l'espace «</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">mon compte</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">» de la Plateforme. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="9" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:31.25pt; margin-bottom:10pt; text-align:justify; padding-left:4.75pt; font-family:'Times New Roman'; font-weight:bold">
					<u>LIENS DE TIERS</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Plateforme peut contenir des liens vers des sites Web de tiers. Tout le contenu de tiers est fourni par des tiers, la Société ne fait aucune promesse, garantie ou représentation concernant le contenu, son authenticité, son exactitude ou la disponibilité, la fiabilité, la sécurité des sites Web de tiers, ou tout autre aspect (y compris les droits sur celui-ci) hors de notre contrôle, et la Société n’est pas responsable des conséquences de votre utilisation du contenu de tiers. Vous assumez le risque d’accès et d’utilisation de contenus tiers.</span>
			</p>
			<ol start="10" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>FORCE MAJEURE ET RAISONS DE TIERS</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-left:18pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Nous ne serons pas tenus responsables en cas de retard, d'interruption ou de non-exécution de nos obligations découlant des présentes CGU, si cela est dû à un cas de force majeure indépendant de notre volonté. Les cas de force majeure comprennent, sans s'y limiter, les épidémies, les pandémies, les catastrophes naturelles, les conflits armés, les grèves, les troubles civils, les actes gouvernementaux, les pannes techniques, les pannes de réseau ou d'autres événements indépendants de notre contrôle raisonnable. </span>
			</p>
			<p style="margin-top:0pt; margin-left:18pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La survenance d'un cas de force majeure ne constitue pas une renonciation à nos droits en vertu des présentes CGU. Tout droit ou recours dont nous disposons en vertu des présentes CGU sera maintenu même en cas de force majeure.</span>
			</p>
			<p style="margin-top:0pt; margin-left:18pt; margin-bottom:10pt">
				<span style="font-family:'Times New Roman'">Vous Devez Immédiatement Informer la Société Si Vous savez ou pensez que votre compte a été piraté ou compromis.</span>
			</p>
			<p style="margin-top:0pt; margin-left:18pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Vous serez responsable de toutes les transactions effectuées via votre compte à tout moment avant la réception par la Société de votre notification.</span>
			</p>
			<p style="margin-top:0pt; margin-left:18pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">En cas de force majeure prolongée empêchant la reprise normale de nos services, nous nous réservons le droit de modifier, suspendre ou résilier nos services de manière permanente. Dans un tel cas, nous vous informerons dans la mesure du possible et nous ne serons pas tenus responsables des dommages ou préjudices résultant de cette modification, suspension ou résiliation.</span>
			</p>
			<ol start="11" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>SUSPENSION TEMPPORAIRE, ANNULATION DE GIFTY</u>
				</li>
			</ol>
			<ol type="1" style="margin:0pt; padding-left:0pt">
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>La Plateforme peut être suspendue par la Société à tout moment sans préavis. Après l'annulation, les services (ou toute partie de ceux-ci) peuvent être rétablis de la manière et selon les conditions d'utilisation que la Société peut, à son entière discrétion, déterminer.
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>Vous pouvez désactiver votre compte à tout moment où vous nous informez de votre désir de le faire, sous réserve toujours de frais de fermeture que nous vous facturons, le cas échéant.
				</li>
				<li style="text-align:justify; line-height:13.5pt; font-family:'Times New Roman'; list-style-position:inside">
					<span style="width:0.95pt; font:7pt 'Times New Roman'; display:inline-block"> </span>La Société se réserve le droit à tout moment de suspendre ou de bloquer l'accès et l'utilisation de la Plateforme (ou de toute partie de celle-ci) pour quelque raison que ce soit et pour toute durée et à toutes conditions que la Société peut, à son entière discrétion, déterminer. Les motifs de suspension ou de résiliation peuvent inclure&#xa0;; mais sans s'y limiter :
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- le compte a été inactif pendant une période consécutive de 24 mois ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- avoir plusieurs comptes d'Utilisateurs ou permettre à des personnes non autorisées d'accéder et d'utiliser le compte ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- pour suspicion de fraude, illégalité, criminalité ou fausse déclaration dans la conduite de votre compte ou votre utilisation de la plate-forme et/ou des services ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous êtes en violation ou nous avons des motifs raisonnables de croire que vous avez enfreint l'une de ces CGU et/ou toutes les conditions générales applicables pouvant être fournies par la Société de temps à autre, ou que vous avez adopté une conduite préjudiciable à la Société ou à notre avis, vos actes sont préjudiciables aux intérêts de celle-ci ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous enfreignez des actes, statuts, lois, règlements, règles, règlements, directives et/ou politiques de toute autorité, organisme de réglementation ou organisme gouvernemental ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous avez agi de mauvaise foi ou avec une intention malveillante, ou que nous avons des motifs raisonnables de croire que votre comportement est nuisible, de nature diffamatoire ou abusif envers d'autres Utilisateurs, des tiers et/ou la Société ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- si nous sommes tenus de le faire conformément à une ordonnance d'un tribunal ou par toute autorité gouvernementale ou réglementaire ayant la juridiction compétente ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous avez fourni de faux documents ou déclaré de fausses informations lors de votre inscription ou candidature à la Société</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous ne fournissez pas les informations supplémentaires que nous pouvons vous demander de temps à autre à des fins de vérification</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">en cas de survenance d'un cas de force majeure, nous nous réservons le droit de suspendre temporairement nos services sans préavis ni responsabilité, jusqu'à la cessation de la situation de force majeure. Nous nous efforcerons de minimiser les impacts sur les Utilisateurs et de rétablir nos services dans les meilleurs délais possibles.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<strong><span style="font-family:'Times New Roman'; ">En cas d'annulation ou de résiliation des Solutions Applicatives :</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- tous les crédits de la Société et/ou points de la Société non utilisés seront réputés avoir expiré immédiatement et seront supprimés de votre compte. Nous vous aiderons à rembourser le solde des crédits la Société inutilisés sur votre compte, sous réserve des conditions d'utilisation ici et de toutes les conditions générales que la Société peut déterminer ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- Vous devez immédiatement payer à la Société tous les frais et charges impayés dus à la Société, à défaut de quoi la Société aura l'entière discrétion d'engager des actions civiles contre Vous, y compris, sans s'y limiter, une demande de dommages-intérêts, une exécution en nature et/ou une mesure provisoire ou injonctive ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- la Société peut, à son entière discrétion, décider de ne pas donner suite à toute demande reçue après la date effective de résiliation ;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- la Société peut, à son entière discrétion, décider de ne pas donner suite à toute confirmation ou demande reçue par la Société entre la date de notification qui vous est donnée et la date effective de résiliation (s'il y a un laps de temps entre les deux dates) ; et/ou</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- par la présente, vous autorisez irrévocablement et inconditionnellement la Société à déduire toutes les sommes dues et dues par Vous à la Société (le cas échéant) des sommes qui vous sont dues par la Société au titre des articles achetés livrés.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- en plus de ce qui précède, dans le cas où votre accès et/ou utilisation du Services Applicatif est suspendu, interrompu ou résilié par nous en raison de transactions suspectes, frauduleuses, illégales ou illégales, y compris, mais sans s'y limiter, les violations de toute loi ( y compris, mais sans s'y limiter, "la loi 06-01 du 20 février 2006 relative à la prévention et à la lutte contre la corruption" ou toute règle, réglementation, politique et/ou directive en vertu de celle-ci), Vous ne pourrez pas continuer à utiliser vos crédits de la Société et/ou vos points de la Société et n'aura pas le droit d'obtenir de l'argent ou un remboursement quel qu'il soit. Il est loisible à la Société de conserver pour une durée indéterminée, ou de traiter à son gré, ou de remettre aux autorités compétentes, tout argent ou remboursement (y compris vos crédits la Société et/ou la valeur monétaire équivalente à vos points Gifty) conformément aux législations, règles, réglementations et/ou directives applicables. Vous ne pourrez prétendre à aucune forme de compensation pour toute perte en résultant.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">- toute disposition des présentes conditions d'utilisation qui, expressément ou implicitement, est destinée à rester en vigueur et à vous lier après la désactivation du compte et/ou la résiliation des services, survivra à cette résiliation. </span>
			</p>
			<ol start="12" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>CONFIDENTIALITE</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Nous respectons et protégeons votre vie privée avec le plus grand engagement. Veuillez-vous référer à notre politique de confidentialité sur les mesures spécifiques que nous prenons pour protéger vos informations personnelles.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Nous pouvons Vous contacter à des fins de marketing et Vous envoyer des informations publicitaires, promotionnelles ou publicitaires conformément à notre politique de confidentialité.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Il convient de noter que la connexion réseau et la transmission de données entre Vous et la Plateforme ne sont pas complètement sécurisées ou confidentielles. Les informations et les données que Vous soumettez à la Plateforme peuvent être interceptées par d’autres. La Société ne peut garantir la sécurité et la confidentialité de la connexion réseau et de la transmission de données entre Vous et la Plateforme.</span><span style="font-family:'Times New Roman'">&#xa0; </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="13" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>TRAITEMENT ET PROTECTION DES DONNEES PERSONNELLES </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous vous engagez à fournir des informations exactes quant à vos coordonnées personnelles ainsi que toute autre information nécessaire à l'accès aux services de la Plateforme et à mettre à jour régulièrement ces informations si nécessaire. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Néanmoins, les informations recueillies sur la Plateforme font l’objet d’un traitement conformément à la Loi n° 18-05 du 10 mai 2018, la Société procède à la collecte de données et constitue des fichiers Utilisateurs et respectera la Politique de Confidentialité à laquelle Vous aurez adhéré.</span><span style="font-family:'Times New Roman'">&#xa0; </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">En acceptant les présentes CGU, Vous acceptez expressément le traitement de vos données à caractère personnel. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Les destinataires des données se limitent exclusivement aux personnes habilitées à connaître vos données en raison de leurs fonctions. Vous bénéficiez d’un droit d’accès et de rectification aux informations qui Vous concernent, que Vous pouvez exercer en vous adressant à notre service client au </span><strong><span style="font-family:'Times New Roman'; ">0770 20 14</span></strong><span style="font-family:'Times New Roman'"> 94 ou par email au </span><strong><span style="font-family:'Times New Roman'; ">contact@gifty-dz.com</span></strong>
			</p>
			<ol start="14" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>LIMITATIONS ET EXCLUSIONS DE RESPONSABILITE</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Dans les limites permises par la loi, nous excluons toutes garanties, expresse ou implicite, concernant notre Plateforme et son contenu. Nous ne garantissons pas que notre Plateforme sera sans erreur, sécurisée, ininterrompue, ou que tout défaut sera corrigé.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société ne donne aucune garantie d’aucune sorte concernant la disponibilité, la durabilité ou l’accessibilité de la Plateforme, l’Utilisateur est en outre informé que la Société peut être amené, à tout moment, sans préavis, à interrompre momentanément l’accès à la Plateforme pour des raisons techniques, notamment pour les raisons de sa maintenance. L’Utilisateur accepte ces interruptions et renonce à toute réclamation à ce titre.</span><span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Sauf indication contraire expresse dans les présentes CGU, notre responsabilité concernant tout produit acquis sur notre Plateforme sera strictement limitée au prix d’achat dudit produit.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société ne sera aucunement responsable après utilisation du bon d’achat ni vis-à-vis des Utilisateurs ni des fournisseurs et points de ventes conventionnes</span><span style="font-family:'Times New Roman'">&#xa0;</span><span style="font-family:'Times New Roman'">; nous dégageons toute responsabilité relative à la qualité du produit, sa livraison, son remboursement et/ou échange.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société ne sera pas responsable des dommages indirects, accessoires, spéciaux ou de toute forme de punition, et la Société ne sera pas responsable de toute perte indirecte, y compris, mais sans s’y limiter, la perte de profits, de revenus, de données ou d’utilisation des données.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La responsabilité totale assumée par la Société et ses partenaires en raison de tout dommage ou perte, causés par votre utilisation de la Plateforme ne doit pas dépasser le montant total payé dans le cadre de votre utilisation de tout service ou fonctionnalité de la Plateforme. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">La Société ne sera pas responsable des erreurs de débit sur le compte de l’Utilisateur, en effet vous devez dans ce cas contacter votre banque ou le numéro vert de la SATIM. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Toutefois si la transaction par carte bancaire CIB/Dahabia se fait avec succès sans pour autant que votre compte soit crédité du montant, vous devez immédiatement contacter le support avec une preuve d’achat (reçu de paiement), nous nous chargerons de régulariser la situation dans un délais de vingt-quatre (24) heures.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="15" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					LIMITATION D’UTILISATION DE L’OPTION TRANSFERT
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’option transfert, permet à un Utilisateur de transférer son solde à partir de son compte vers le compte d’un autre Utilisateur. Ce transfert est</span><u><span style="font-family:'Times New Roman'; ">&#xa0; </span></u><span style="font-family:'Times New Roman'">plafonné à la somme de trente mille dinars (30.000 DA) par semaine. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’Utilisation de l’option transfert doit demeurer à titre personnel entre Utilisateur et non à titre commercial, étant entendu que les points ne peuvent correspondre à une monnaie d’échange numérique. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<u><span style="font-family:'Times New Roman'; ">Etant entendu que la Plateforme </span></u><span style="font-family:'Times New Roman'">et une marketplace de cadeau digital (bons d’achat) qui permet d’acquérir ou offrir un cadeau à titre professionnelle ou personnelle.</span>
			</p>
			<ol start="16" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>&#xa0;</u><u>CONDITION ET MODALITE DE REMBOURSEMENT</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-left:21.3pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify">
				<span style="font-family:'Times New Roman'">-</span><span style="width:14.34pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><strong><em><u><span style="font-family:'Times New Roman'; ">Remboursement en cas de refus chez le fournisseur (commerçant conventionné) : </span></u></em></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Dans le cas où le fournisseur refuse d’accepter les bons d’achat, vous devez contacter immédiatement le support de la Plateforme sur le numéro suivant : 0770 20 14 94</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Si la situation n’est pas réglé, et si vous avez déjà convertie un bon d’achat dudit fournisseur, la Société remboursera le montant déboursé pour le bon d’achat en point Gifty dans un délai de</span><span style="font-family:'Times New Roman'">&#xa0; </span><span style="font-family:'Times New Roman'">72h ouvrable.</span>
			</p>
			<p style="margin-top:0pt; margin-left:21.3pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify">
				<span style="font-family:'Times New Roman'">-</span><span style="width:14.34pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><strong><em><u><span style="font-family:'Times New Roman'; ">Remboursement des bons d’achat utilisables en ligne</span></u></em></strong><strong><em><u><span style="font-family:'Times New Roman'; ">&#xa0;</span></u></em></strong><strong><em><u><span style="font-family:'Times New Roman'; ">: </span></u></em></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Si vous avez converti un bon d’achat chez un fournisseur dont le service est utilisable en ligne (sur son site web) et que ce dernier ne fonctionne pas (code invalide, expiré oi déjà utilisé)</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’Utilisateur doit prendre une capture d’écran dudit message d’erreur et contacter le support de la Plateforme sur le numéro :0770 20 14 94 ou envoyer par mail a l’adresse : contact@gifty-dz.com</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Après vérification auprès du fournisseur et si cela s’avère véridique, la Société remplacera le dit bon d’achat ou offrira un remboursement en points GIFTY dans un délai de soixante-douze (72) heures.</span>
			</p>
			<p style="margin-top:0pt; margin-left:21.3pt; margin-bottom:10pt; text-indent:-18pt; text-align:justify">
				<span style="font-family:'Times New Roman'">-</span><span style="width:14.34pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><strong><em><u><span style="font-family:'Times New Roman'; ">Remboursement des bons d’achat transmis par SMS</span></u></em></strong><strong><em><u><span style="font-family:'Times New Roman'; ">&#xa0;</span></u></em></strong><strong><em><u><span style="font-family:'Times New Roman'; ">: </span></u></em></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Si vous émettez le bon d’achat via SMS et que le destinataire ne le reçoit pas dans un délai de deux (02) heures, vous devez contacter le support de la Plateforme afin que ce dernier puisse envoyer le bon d’achat via SMS ou autre méthode choisi (WhatsApp, vider, télégramme).</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Dans tous les cas, la Société s’engage à rembourser et/ou remplacer les bons d’achat électroniques invalides (déjà utilisé ou expiré) et édités sur la plateforme uniquement si la date d’utilisation du bon d’achat est antérieur à la date de son achat.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt">
				<span style="font-family:'Times New Roman'">Les remboursements se feront uniquement en points Gifty convertibles en bon d’achat. </span>
			</p>
			<ol start="17" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					<u>Modalité de suppression d’un compte Utilisateur </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L’Utilisateur qui souhaite supprimer son compte, en fait la demande via la Plateforme (contact@gifty-dz.com), celui-ci recevra une demande de confirmation de la pat de la Société par email, sms ou appel téléphonique. </span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Aussi, celui-ci sera informé du solde de ses point restant afin de les utiliser dans un délai de 48h, passé ce délai la suppression entrainera la perte du solde restant. </span>
			</p>
			<ol start="18" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>REGLES D'USAGE D'INTERNET</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-left:36pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’Utilisateur déclare accepter les caractéristiques et les limites d’Internet, et notamment reconnaît que :</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">La Société n’assume aucune responsabilité sur les services accessibles par Internet et n’exerce aucun contrôle de quelque forme que ce soit sur la nature et les caractéristiques des données qui pourraient transiter par l’intermédiaire de son centre serveur.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’Utilisateur reconnaît que les données circulant sur Internet ne sont pas protégées notamment contre les détournements éventuels. La présence du logo de la Société institue une présomption simple de validité. La communication de toute information jugée par l’Utilisateur de nature sensible ou confidentielle se fait à ses risques et périls.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’Utilisateur reconnaît que les données circulant sur Internet peuvent être réglementées en termes d’usage ou être protégées par un droit de propriété.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’Utilisateur est seul responsable de l’usage des données qu’il consulte, interroge et transfère sur Internet.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">L’Utilisateur reconnaît que la Société ne dispose d’aucun moyen de contrôle sur le contenu des services accessibles sur Internet.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="19" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>NULLITE – DIVISIBILITE </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<strong><span style="font-family:'Times New Roman'; ">&#xa0;</span></strong>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Au cas où l'une quelconque des clauses est reconnue ou déclarée nulle, seule ladite clause sera réputée non écrite et toutes les autres clauses resteront en vigueur.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">La renonciation de la Société à se prévaloir d’une clause des présentes Conditions Générales n’implique pas renonciation définitive au bénéfice de cette clause ou d’une quelconque autre clause des présentes Conditions Générales. Si l’une quelconque des clauses des présentes Conditions Générales s’avérait nulle, la validité des autres dispositions n’en serait pas affectée.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="20" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>MODIFICATION DU SERVICE OU DES CONDITIONS GENERALES D’UTILISATION </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Nous nous réservons le droit de modifier, suspendre ou interrompre tout ou partie de notre service, à tout moment et sans préavis. Cela peut inclure l'ajout ou la suppression de fonctionnalités, la modification des procédures, des moyens de fonctionnement ou des ressources nécessaires pour accéder à notre service.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Nous nous réservons également le droit de modifier ces CGU à tout moment. Les modifications seront effectives dès leur publication sur notre Plateforme ou par tout autre moyen de communication approprié. Il est de Votre responsabilité de consulter régulièrement les CGU pour prendre connaissance des éventuelles modifications. Votre utilisation continue de notre Plateforme après la publication des modifications constitue votre acceptation des nouvelles CGU.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="21" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>REFUS DES MODIFICATIONS</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Si vous n'êtes pas d'accord avec les modifications apportées, Vous avez le droit de résilier votre compte et de cesser d'utiliser notre Plateforme.</span><span style="font-family:'Times New Roman'">&#xa0; </span><span style="font-family:'Times New Roman'">La poursuite de Votre utilisation de notre Plateforme après la publication des modifications constitue votre acceptation desdites modifications.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="22" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>CONSEQUENCES DE LA RESILIATION </u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">En cas de résiliation de Votre compte en raison de désaccord avec les modifications, nous ne serons pas responsables envers vous ou tout tiers pour toute perte, dommage ou préjudice découlant de ladite résiliation.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="23" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>RENONCIATION</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Si Vous enfreignez ces Conditions Générales et que nous ne prenons aucune action, nous serions toujours en droit d'utiliser nos droits et voies de recours dans toutes les autres situations où Vous violeriez ces Conditions Générales.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="24" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; text-align:justify; line-height:normal; font-family:'Times New Roman'; font-weight:bold">
					<u>INTERDICTION D'INGENIERIE INVERSE</u>
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Vous ne pouvez et Vous n'encouragerez pas, ni n'assisterez ou n'autoriserez qui que ce soit à (i) copier, modifier, altérer d'une autre façon un logiciel de la Société en tout ou partie, créer des œuvres dérivées à partir ou du logiciel de la Société ou (ii) effectuer de l'ingénierie inverse, décompiler ou désassembler un logiciel de la Société en tout ou partie, sauf dans les limites autorisées par la loi.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<ol start="25" type="1" style="margin:0pt; padding-left:0pt">
				<li style="margin-left:36pt; margin-bottom:10pt; text-align:justify; font-family:'Times New Roman'; font-weight:bold">
					LÉGISLATION APPLICABLE ET JURIDICTION
				</li>
			</ol>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">L'utilisation de notre Plateforme seront régis par le droit algérien.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">Tout différend découlant ou lié à l'utilisation de la Plateforme sera soumis à la juridiction non exclusive des cours et tribunaux près du siège social de la Société.</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt; text-align:justify">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">Pour toute question relative aux présentes Conditions Générales d’Utilisation de la Plateforme, Vous pouvez nous écrire à l’adresse email suivante : contact@gifty-dz.com</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:0pt; text-align:justify; line-height:normal">
				<span style="font-family:'Times New Roman'">&#xa0;</span>
			</p>
			<p style="margin-top:0pt; margin-bottom:10pt">
				&#xa0;
			</p>
			<div style="clear:both">
				<p style="margin-top:0pt; margin-bottom:0pt; text-align:right; line-height:normal">
					1
				</p>
				<p style="margin-top:0pt; margin-bottom:0pt; line-height:normal">
					&#xa0;
				</p>
			</div>
		</div>
	<p style="bottom: 10px; right: 10px; position: absolute;"><a href="https://wordtohtml.net" target="_blank" style="font-size:11px; color: #d0d0d0">Converted to HTML with WordToHTML.net</a></p>
          </div>

 

         

          

        </v-card-text>
      </v-card>

      <p class="pt-10 text-center">
        © Copyright {{ new Date().getFullYear() }}
        <a class="text-primary" href="https://gifty-dz.com/" target="_blank"
          >Gifty Algérie</a
        >
        Tous droits réservés.
      </p>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.title_h1{
    line-height: 40px;
}
.paragraph {
  font-size: 19px !important;
  font-weight: 400;
  line-height: 26px;
}
</style>